export default [
    {
        title: 'Sidebar.help.title',
        name: 'help',
        hidden: false,
        children: [
            {
                title: 'Sidebar.help.faq',
                route: 'helpFaq',
                icon: 'help-circle.png',
                name: 'faq',
                hidden: false
            },
            {
                title: 'Sidebar.help.support',
                route: 'helpSupport',
                icon: 'lifebuoy.png',
                name: 'support',
                hidden: false
            },
            {
                title: 'Sidebar.help.videoGuide',
                route: 'helpVideoGuide',
                icon: 'videoGuide.png',
                name: 'helpVideoGuide',
                hidden: false
            },
        ],
    },
];
