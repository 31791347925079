<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex border-left border-right align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          <span
            v-if="
              currentUsers.current_account && currentUsers.current_account.uuid
            "
            >{{ currentUsers.current_account.name }}</span
          >
          <!-- {{ userData.fullName || userData.username }} -->
          <!-- Club #1 -->
        </p>
      </div>
      <!-- <b-avatar
        size="40"
        src="@/assets/icon/clubIcon.png"
        variant="light-primary"
        class="badge-minimal"
      >-->
      <!--      </b-avatar>-->

      <feather-icon size="16" icon="ChevronDownIcon" class="mr-1" />
    </template>

    <div v-if="userData.club_accounts.length > 0">
      <b-dropdown-item
        v-for="(item, index) in userData.club_accounts"
        :key="index"
        @click="chooseAccount(item)"
        link-class="d-flex align-items-center"
      >
        <!-- :to="{name: item.link}" -->
        <!-- <feather-icon
                    :size="item.iconSize"
                    :icon="item.icon"
                    class="mr-50"
                /> -->
        <span class="text-truncate" v-text="item.name" />
      </b-dropdown-item>
    </div>

    <!-- <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
            <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
            <span> {{ $t('UserDropdown.Logout') }}</span>
        </b-dropdown-item> -->
  </b-nav-item-dropdown>
</template>

<script>
import useJwt from "@/auth/jwt/useJwt";
import { avatarText } from "@core/utils/filter";
import useAppConfig from "@core/app-config/useAppConfig";
import { mapGetters } from "vuex";

export default {
  name: "clubdropdown",
  data() {
    return {
      config: useAppConfig(),
      userData: JSON.parse(localStorage.getItem("userData")),
      avatarText,
      dataDropdown: [],
    };
  },
  computed: {
    currentUsers() {
      return this.$store.getters["appConfig/currentUser"];
    },
    message() {
      return this.GET_LOCALE === "sv" ? "message" : "eng_message";
    },
    getLang() {
      return this.config.lang.value;
    },
    ...mapGetters({
      GET_HIDDEN_RESOURCE_ID: "appConfig/getResourceId",
      GET_LOCALE: "appConfig/getLocale",
    }),
  },
  mounted() {
    // if (this.userData && this.userData.club_accounts.length > 0) {
    //     this.dataDropdown = this.userData.club_accounts
    // }
  },
  methods: {
    getHiddenResources() {
      this.$useJwt
        .hiddenResource(
          { method: "get", URL: `/${this.GET_HIDDEN_RESOURCE_ID}` },
          {}
        )
        .then((resp) => {
          localStorage.setItem("hiddenResource", JSON.stringify(resp.data));
          this.$store.commit("appConfig/UPDATE_HIDDEN_RESOURCE", resp.data);
        });
    },
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);

      // Remove userData from localStorage
      localStorage.removeItem("userData");

      // Redirect to login page
      this.$router.push({ name: "login" });
    },
    chooseAccount(account) {
      this.$store.commit("appConfig/UPDATE_CURRENT_ACCOUNT", account);

      this.$useJwt.customUser({ URL: "/me", method: "get" }).then((resp) => {
        if (
          resp?.data?.data?.connect_info &&
          resp?.data?.data?.connect_info.connect_endpoint
        ) {
          localStorage.setItem(
            "__connectInfo__",
            JSON.stringify(resp?.data?.data?.connect_info)
          );
        } else {
          localStorage.removeItem("__connectInfo__");
        }
      });

      this.$useJwt
        .customUser(
          { URL: "/me", method: "put" },
          { current_account_uuid: account.uuid }
        )
        .then((res) => {
          this.config.currentUser = res.data.data;
          this.$store.commit("appConfig/UPDATE_HIDDEN_RESOURCE_ID", {
            id: this.config.currentUser.hidden_resources_id,
          });

          this.$useJwt
            .hiddenResource(
              { method: "get", URL: `/${this.GET_HIDDEN_RESOURCE_ID}` },
              {}
            )
            .then((resp) => {
              localStorage.setItem("hiddenResource", JSON.stringify(resp.data));
              this.$store.commit("appConfig/UPDATE_HIDDEN_RESOURCE", resp.data);
              setTimeout(function () {
                window.location.reload();
              }, 500);
              this.popupMsg(
                this.$t("Message.Success"),
                res.data[this.message],
                "CheckIcon",
                "success"
              );
            });
        })
        .catch((err) => {
          this.popupMsg(
            this.$t("Message.Failed"),
            err.response.data.message,
            "AlertTriangleIcon",
            "danger"
          );
        });
    },
  },
};
</script>
