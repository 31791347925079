export default [
    {
        title: 'Sidebar.configuration.title',
        name: 'configuration',
        hidden: false,
        children: [
            {
                title: 'Sidebar.configuration.integrations',
                route: 'configurationIntegrations',
                icon: 'link.png',
                name: 'integrations',
                hidden: false,
            },
            {
                title: 'Sidebar.configuration.settings',
                route: 'configurationSettings',
                icon: 'cog.png',
                name: '',
                hidden: false,
            },
            {
                title: 'Sidebar.configuration.eventLog',
                route: 'configurationEventLog',
                icon: 'text-box-multiple.png',
                name: 'event_logs',
                hidden: false,
            },
            {
                title: 'Sidebar.configuration.users',
                route: 'configurationUsers',
                icon: 'account-circle.png',
                name: 'users',
                hidden: false,
            },
        ],
    },
];
