<template>
    <li
        v-if="canViewVerticalNavMenuLink(item) && item.hidden === false"
        class="nav-item text-white"
        :class="{
            active: isActive,
            disabled: item.disabled,
        }"
    >
<!--{{ item.hidden }}-->
        <router-link :to="{ name : 'helpSupport'}"
            v-if="item.title == 'Sidebar.help.support'"
            class="d-flex smallGap text-white align-items-center"
        >
            <!-- @click="eventBUs.$emit('open-support')" -->
            <!-- v-b-modal.modal-prevent-closing -->
            <img
                v-if="item.icon"
                :src="require(`@/assets/icon/${item.icon}`)"
                height="20px"
            />
            <span
                class="menu-title text-white text-truncate"
                v-text="$t(item.title)"
            />
        </router-link>
        <b-link
            v-else
            v-bind="linkProps"
            class="d-flex smallGap text-white align-items-center"
        >
            <img
                v-if="item.icon"
                :src="require(`@/assets/icon/${item.icon}`)"
                height="20px"
            />
            <span
                class="menu-title text-white text-truncate"
                v-text="$t(item.title)"
            />
        </b-link>
    </li>
</template>

<script>
import {useUtils as useAclUtils} from '@core/libs/acl';
import {useUtils as useI18nUtils} from '@core/libs/i18n';
import useVerticalNavMenuLink from './useVerticalNavMenuLink';
import mixinVerticalNavMenuLink from './mixinVerticalNavMenuLink';
import bus from '@/bus';


export default {
    mixins: [mixinVerticalNavMenuLink],
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    data () {
        return {
            eventBUs : bus
        }
    },
    setup(props) {
        const {isActive, linkProps, updateIsActive} = useVerticalNavMenuLink(
            props.item
        );
        const {t} = useI18nUtils();
        const {canViewVerticalNavMenuLink} = useAclUtils();

        return {
            isActive,
            linkProps,
            updateIsActive,

            // ACL
            canViewVerticalNavMenuLink,

            // i18n
            t,
        };
    },
};
</script>
