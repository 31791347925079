<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Sync Accounting Data Button -->
    <sync-accounting-data-button />

    <!-- Right Col -->
    <b-navbar-nav class="nav align-items-center ml-auto">
      <locale />
      <!-- <dark-Toggler class="d-none d-lg-block" /> -->
      <notification-dropdown />
      <ClubDropdown />
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import Locale from "./components/Locale.vue";
import DarkToggler from "./components/DarkToggler.vue";
import NotificationDropdown from "./components/NotificationDropdown.vue";
import UserDropdown from "./components/UserDropdown.vue";
import ClubDropdown from "./components/ClubDropdown.vue";
import SyncAccountingDataButton from './components/SyncAccountingDataButton.vue'

export default {
  components: {
    SyncAccountingDataButton,
    Locale,
    DarkToggler,
    ClubDropdown,

    NotificationDropdown,
    UserDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
};
</script>
