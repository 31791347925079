import accountData from './accountData';
import configuration from './configuration';
import golfRelated from './golfRelated';
import help from './help';
import memberCare from './memberCare';

const currentRoute = [
    {
        title: 'Sidebar.Dashboard',
        route: 'dashboard',
        icon: 'dashboard.png',
        name:'dashboard',
        hidden: false
    },
];

export default [
    ...currentRoute,
    ...accountData,
    ...golfRelated,
    ...configuration,
    ...help,
    ...memberCare,
];
