<template>
  <div class="sync-button-container">
    <b-button
      id="sync-button"
      class="sync-button"
      @click="syncData"
      style="color: white; "
    >
      <mdicon
        :width="18"
        :height="18"
        class="icon-refresh text-secondaryColor"
        name="refresh"
      />
      <span class="button-text">Synka resurser från ekonomiprogrammet</span>
    </b-button>
    
    <b-tooltip target="sync-button" placement="top">
      {{ tooltipContent }}
    </b-tooltip>
  </div>
</template>

  
  <script>
  export default {
    data() {
      return {
        showTooltip: false,
        tooltipContent: 'Klicka här för att hämta nya resurser från ekonomiprogrammet (bl.a. betalningsvillkor, enheter, kostnadsställen).'
      };
    },
    methods: {
        syncData() {
        this.$useJwt.customSync({URL: '/accounting_data', method: 'get'})
          .then(res => {
            this.fetch = true
            this.$emit('close-toast')
            this.popupMsg(
                this.$t('Message.Success'),
                res.data.message,
                'CheckIcon',
                'success'
            );
          })
          .catch(err => {
            this.loadingAction = false
            this.popupMsg(
                this.$t('Message.Failed'),
                err.response.data[this.message],
                'AlertTriangleIcon',
                'danger'
            );
          })
      }
    }
  };
  </script>
  
  

  
  <style>
  /* Existing styles... */

  .sync-button-container {
  position: relative;
  display: inline-block;
}

  
  /* Sync button with icon styling */
  .sync-button {
    display: inline-flex;
    align-items: flex-start; /* Align items to the start of the container (top) */
    padding: 5px; /* Adjust the padding as needed */
    position: relative; /* Position relative for absolute positioning of the icon */
    background-color: white !important;
  }
  
  /* Positioning the icon to the top left of the text */
  .icon-refresh {
    position: absolute;
    top: 0; /* Adjust the top as needed */
    left: 0; /* Adjust the left as needed */
    margin-left: 10px;
  }
  
  /* Button text styling */
  .button-text {
    margin-left: 24px; /* This adds space between the icon and the text, adjust as needed */
    color: gray;
  }


  </style>