export default [
    {
        title: 'Sidebar.memberCare.title',
        name: 'member_care',
        hidden: false,
        children: [
            {
                title: 'Sidebar.memberCare.emailSendOut',
                route: 'memberCareEmailSendout',
                icon: 'email.png',
                name: 'email',
                hidden: false,
            },
            {
                title: 'Sidebar.memberCare.emailTemplate',
                route: 'emailTemplateList',
                icon: 'email.png',
                name: 'emailTemplateList',
                hidden: false,
            },
            {
                title: 'Sidebar.memberCare.smsSendOut',
                route: 'memberCareSmsSendout',
                icon: 'sms.png',
                name: 'sms',
                hidden: false,
            },
        ],
    },
];
