<template>
    <div>
        <!-- <Modal
            :title="$t(locale.title)"
            id="modal-prevent-closing"
            :okEvent="submitSupport"
            :closeEvent="closeModal"
            :options="{
                'ok-only': true,
                'cancel-variant': 'outline-secondary',
                'ok-title': $t(locale.send),
            }"
        >
            <b-card-text
                class="d-flex flex-column justify-content-center"
                style="gap: 1rem"
            >
                <div class="">
                    <ValidationObserver ref="form">
                        <b-row>
                            <b-col cols="12" md="6">
                                <ValidationProvider
                                    #default="{errors}"
                                    rules="required"
                                    :name="$t(locale.notifSubject)"
                                >
                                    <b-form-input
                                        id="login-email"
                                        v-model="dataForm.subject"
                                        :placeholder="$t(locale.notifSubject)"
                                    />

                                    <small class="text-danger" v-text="errors[0]" />
                                </ValidationProvider>
                            </b-col>
                        </b-row>

                        <ValidationProvider
                            rules="required"
                            #default="{errors}"
                            :name="$t(locale.msg)"
                        >
                            <b-form-group
                                class="mt-2"
                                :label="$t(locale.msgPlaceholder)"
                            >
                                <b-form-textarea
                                    id="textarea-rows"
                                    v-model="dataForm.message"
                                    :placeholder="$t(locale.msgPlaceholder)"
                                    rows="8"
                                />
                                <small class="text-danger" v-text="errors[0]" />
                            </b-form-group>
                        </ValidationProvider>
                    </ValidationObserver>
                </div>
            </b-card-text>
        </Modal> -->
        
        <CustomNoHeaderModalVue v-if="openSupport" width="w-11/12 md:w-1/2"  :title="''"  @handleClose="openSupport = false" :close="!true">
            <div class="flex flex-col p-1" slot="header">
                <h2 class="text-xl md:text-2xl font-semibold font-poppins text-gray-900">{{ $t(locale.title) }}</h2>
            </div>
            <div class="flex flex-col p-2" slot="body">
                    <ValidationObserver ref="form">
                        <form @submit.prevent="submitSupport">
                            <b-row>
                                <b-col cols="12" md="6">
                                    <ValidationProvider
                                        #default="{errors}"
                                        rules="required"
                                        :name="$t(locale.notifSubject)"
                                    >
                                        <b-form-input
                                            id="login-email"
                                            v-model="dataForm.subject"
                                            :placeholder="$t(locale.notifSubject)"
                                        />

                                        <small class="text-danger" v-text="errors[0]" />
                                    </ValidationProvider>
                                </b-col>
                            </b-row>

                            <ValidationProvider
                                rules="required"
                                #default="{errors}"
                                :name="$t(locale.msg)"
                            >
                                <b-form-group
                                    class="mt-2"
                                    :label="$t(locale.msgPlaceholder)"
                                >
                                    <b-form-textarea
                                        id="textarea-rows"
                                        v-model="dataForm.message"
                                        :placeholder="$t(locale.msgPlaceholder)"
                                        rows="8"
                                    />
                                    <small class="text-danger" v-text="errors[0]" />
                                </b-form-group>
                            </ValidationProvider>

                            <div class="flex">
                                <button type="submit" class="flex c-p-3 text-center focus:outline-none bg-theme-teal text-white whitespace-nowrap rounded-md">
                                    <span v-if="loadingStatus">
                                        <b-spinner
                                            small
                                            type="grow"
                                        />
                                        {{ $t('Message.loading') }}...
                                    </span>

                                    <span
                                    class="whitespace-nowrap text-center w-full text-base"
                                        v-else
                                        v-text="
                                            $t(locale.send)
                                        "
                                    />
                                </button>
                            </div>
                        </form>
                    </ValidationObserver>
                </div>
        </CustomNoHeaderModalVue>
    </div>
</template>

<script>
import {ValidationProvider, ValidationObserver} from 'vee-validate';
import useAppConfig from '@core/app-config/useAppConfig';
import CustomNoHeaderModalVue from '@/@core/customComponent/CustomNoHeaderModal.vue';
import bus from './../bus'
import {mapGetters} from "vuex";

export default {
    props: {
        supportModal: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        ValidationProvider,
        ValidationObserver,
        CustomNoHeaderModalVue
    },
    data() {
        return {
            loadingStatus: false,
            openSupport : false,
            config: useAppConfig(),
            dataForm: {
                subject: '',
                message: '',
            },
            locale: {
                title: 'EventLogCard.title',
                send: 'UsersCard.send',
                notifSubject: 'Notification.subject',
                title: 'SupportCard.title',
                msgPlaceholder: 'SupportCard.messagePlaceholder',
                msg: 'SupportCard.msg',
                loading: 'Message.loading',
            },
        };
    },
    computed: {
        openModal: {
            get() {
                return this.supportModal;
            },
            set(value) {
                //
            },
        },
        message() {
             return this.GET_LOCALE === 'sv' ? 'message' : 'eng_message'
        },
        getLang() {
            return this.config.lang.value;
        },
      ...mapGetters({
        GET_LOCALE: 'appConfig/getLocale'
      })
    },
    mounted () {
        bus.$on('open-support', _ => {
            this.openSupport = true
        })
    },
    methods: {
        closeModal() {
            this.$emit('supportModal', false);
        },
        submitSupport() {
            // event.preventDefault();

            this.$refs.form.validate().then(res => {
                if (res) {
                    this.loadingStatus = true
                    this.$useJwt
                        .openTicket(this.dataForm)
                        .then(res => {
                            this.loadingStatus = false
                            this.$nextTick(() => {
                                this.openSupport = false
                                // this.$refs['modal'].toggle('#toggle-btn');
                            });

                            this.popupMsg(
                                this.$t('Message.Success'),
                                res.data[this.message],
                                'CheckIcon',
                                'success'
                            );
                        })
                        .catch(err => {
                            this.openSupport = false
                            this.loadingStatus = false
                            this.popupMsg(
                                this.$t('Message.Failed'),
                                err.response.data[this.message],
                                'AlertTriangleIcon',
                                'danger'
                            );
                        })
                        // .finally(() => {
                        //     this.loadingStatus = false

                        //     this.$refs.form.reset();
                        //     this.dataForm = {
                        //         subject: '',
                        //         message: '',
                        //     };
                        // });
                }
            });
        },
    },
};
</script>
