export default [
    {
        title: 'Sidebar.golfRelated.title',
        name: 'golf_related',
        hidden: false,
        children: [
            {
                title: 'Sidebar.golfRelated.payment',
                route: 'golfRelatedInvoicePayment',
                icon: 'paymentIcon.png',
                name: 'golfRelatedInvoicePayment',
                hidden: false
            },
            {
                title: 'Sidebar.golfRelated.members',
                route: 'golfRelatedMembers',
                icon: 'accountgroupoutline.png',
                name: 'members',
                hidden: false
            },
            {
                title: 'Sidebar.golfRelated.git',
                route: 'golfRelatedGit',
                icon: 'git.png',
                name: 'git',
                hidden: false
            },
            {
                title: 'Sidebar.golfRelated.exports',
                route: 'golfRelatedExports',
                icon: 'applicationexport.png',
                name: 'exports',
                hidden: false
            },
            {
                title: 'Sidebar.golfRelated.receipts',
                route: 'golfRelatedReceipts',
                icon: 'receipttext.png',
                name: 'receipts',
                hidden: false
            },
            {
                title: 'Sidebar.golfRelated.fees',
                route: 'golfRelatedFees',
                icon: 'cash.png',
                name: 'fees',
                hidden: false
            },
        ],
    },
];
