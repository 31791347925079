<template>
  <b-modal
    id="modal-prevent-notifications"
    modal-class="modal-primary"
    ok-variant="danger"
    ref="modal"
    :title="$t(locale.title)"
    @close="closeModal"
    @cancel="closeModal"
    @hide="closeModal"
    :hide-footer="true"
  >
    <!-- :ok-title="$t(locale.delete)"
        ok-only
        @ok="deleteNotification" -->
    <b-card-text>
      <Loading v-if="loadingState" />
      <div
        v-if="showNotification"
        class="p-2 d-flex flex-column"
        style="gap: 1.5rem"
      >
        <div class="">
          <p class="h6" v-text="$t('Notification.date')" />
          <p class="mb-0 pb-0" v-text="formatDate(receiveDate)" />
        </div>

        <div class="">
          <p class="h6" v-text="$t('Notification.subject')" />
          <p class="mb-0 pb-0" v-text="notification[subject]" />
        </div>

        <div class="">
          <p class="h6" v-text="$t('Notification.message')" />
          <p class="mb-0 pb-0" v-text="notification[message]" />
        </div>
      </div>
    </b-card-text>
    <div class="flex justify-end gap-3 mt-2">
      <b-button
        variant="outline-danger"
        size="md"
        @click="$bvModal.hide('modal-prevent-notifications')"
      >
        <span v-text="'Close'" />
      </b-button>
      <b-button
        variant="primary"
        v-if="!notification.archived"
        size="md"
        :disabled="archiving"
        @click="updateNotification(notification.uuid, 'ARCHIVED')"
      >
        <span v-text="archiving ? $t('Message.loading') : $t(locale.archive)" />
      </b-button>
      <b-button
        variant="warning"
        v-if="notification.read"
        size="md"
        :disabled="unreading"
        @click="updateNotification(notification.uuid, 'UNREAD')"
      >
        <span v-text="unreading ? $t('Message.loading') : $t(locale.unread)" />
      </b-button>
      <b-button
        variant="danger"
        size="md"
        :disabled="deleting"
        @click="deleteNotification"
      >
        <span v-text="deleting ? $t('Message.loading') : $t(locale.delete)" />
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import useAppConfig from "@core/app-config/useAppConfig";
import { dateMixins } from "@/mixins/dateMixins";
import {mapGetters} from "vuex";

export default {
  mixins: [dateMixins],
  data() {
    return {
      openModal: true,
      config: useAppConfig(),
      notification: "",
      showNotification: false,
      loadingState: true,
      deleting: false,
      archiving: false,
      unreading: false,
      receiveDate: "",
      locale: {
        title: "HomeCard.Notification",
        delete: "HomeCard.Delete",
        unread: "HomeCard.Unread",
        archive: "HomeCard.Archived",
      },
    };
  },

  watch: {
    uuid(uuid) {
      if (uuid) this.getNotification(uuid);
    },
  },

  computed: {
    uuid() {
      return this.$store.getters["appConfig/uuidNotification"];
    },
    message() {
      if (this.getLang == "") {
        return this.swedishLang ? "swe_message" : "eng_message";
      } else return this.getLang == "sv" ? "swe_message" : "eng_message";
    },
    subject() {
      if (this.getLang == "") {
        return this.swedishLang ? "swe_subject" : "eng_subject";
      } else return this.getLang == "sv" ? "swe_subject" : "eng_subject";
    },

    resultMsg() {
      if (this.getLang == "") {
        return this.swedishLang ? "message" : "eng_message";
      } else return this.getLang == "sv" ? "message" : "eng_message";
    },

    getLang() {
      return this.config.lang.value;
    },
    ...mapGetters({
      GET_LOCALE: 'appConfig/getLocale'
    })
  },

  methods: {
    closeModal() {
      const { storeUuidNotification, refreshNotification } = useAppConfig();

      //   if (this.notification.data.read == false) {
      this.$useJwt
        .updateNotifications(this.uuid, { read: true })
        .then((res) => {
          refreshNotification.value = true;
          this.getNotification(uuid);
        });
      //   }

      storeUuidNotification.value = "";

      this.showNotification = false;
      this.loadingState = true;
    },
    deleteNotification(event) {
      const { refreshNotification } = useAppConfig();
      event.preventDefault();

      this.$useJwt.deleteNotifications(this.uuid).then((res) => {
        this.popupMsg(
          this.$t("Message.Success"),
          res.data[this.resultMsg],
          "CheckIcon",
          "success"
        );
        this.$refs["modal"].hide();
        refreshNotification.value = true;
      });
    },

    getNotification(uuid) {
      this.$useJwt
        .getNotificationsById(uuid)
        .then((res) => {
          const { received } = res.data.data;
          this.receiveDate = received;
          this.notification = res.data.data;
          this.markNotificationRead(uuid);
        })
        .finally(() => {
          this.showNotification = true;
          this.loadingState = false;
        });
    },
    markNotificationRead(uuid) {
      this.$useJwt.updateNotifications(uuid, { read: true }).then((res) => {
      });
    },
    updateNotification(uuid, key) {
      if (key === "UNREAD") {
        this.unreading = true;
      } else {
        this.archiving = true;
      }
      const mode = key === "UNREAD" ? { read: false } : { archived: true };
      this.$useJwt
        .updateNotifications(uuid, mode)
        .then((res) => {
          const { storeUuidNotification, refreshNotification } = useAppConfig();
          this.archiving = false;
          this.unreading = false;
          refreshNotification.value = true;
          storeUuidNotification.value = "";
          this.$bvModal.hide("modal-prevent-notifications");
          this.popupMsg(
            this.$t("Message.Success"),
            res.data[this.message],
            "CheckIcon",
            "success"
          );
        })
        .catch((err) => {
          this.archiving = false;
          this.unreading = false;
          if (err.response.data.error) {
            this.popupMsg(
              this.$t("Message.Failed"),
              err.response.data.error,
              "AlertTriangleIcon",
              "danger"
            );
          } else {
            this.popupMsg(
              this.$t("Message.Failed"),
              err.response.data[this.message],
              "AlertTriangleIcon",
              "danger"
            );
          }
        });
    },
  },
};
</script>

<style></style>
