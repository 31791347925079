export default [
    {
        title: 'Sidebar.acountingGroup.title',
        icon: 'acountingArticleIcon',
        name: 'accounting_data',
        hidden: false,
        children: [
            {
                title: 'Sidebar.acountingGroup.articles',
                route: 'acountingArticle',
                icon: 'archive.png',
                hidden: false,
                name: 'articles'
            },
            {
                title: 'Sidebar.acountingGroup.contracts',
                route: 'acountingContracts',
                icon: 'file-document-edit.png',
                hidden: false,
                name: 'contracts'
            },
            {
                title: 'Sidebar.acountingGroup.invoices',
                route: 'acountingInvoices',
                icon: 'file-document.png',
                hidden: false,
                name: 'invoices'
            },
            {
                title: 'Sidebar.acountingGroup.customers',
                route: 'acountingCustomers',
                icon: 'account-multiple-outline.png',
                hidden: false,
                name: 'customers'
            },
            {
                title: 'Sidebar.acountingGroup.statistics',
                route: 'acountingStatistics',
                icon: 'chart-line.png',
                hidden: false,
                name: 'statistics'
            },

            // //Try current route

            // {
            //     title: 'Sidebar.Duplicates',
            //     route: 'duplicates',
            //     //icon: 'check-all',
            // },
            // {
            //     title: 'Sidebar.Settings',
            //     route: 'settings',
            //     //icon: 'recycle',
            // },
            // {
            //     title: 'Sidebar.Subscription',
            //     route: 'subscription',
            //     //icon: 'view-dashboard',
            // },
            // {
            //     title: 'Sidebar.EventLog',
            //     route: 'eventLog',
            //     //icon: 'logout',
            // },
            // {
            //     title: 'Sidebar.Users',
            //     route: 'users',
            //     //icon: 'account-group',
            // },
            // {
            //     title: 'Sidebar.BillingPage',
            //     route: 'billing',
            //     //icon: 'wallet',
            // },
            // {
            //     title: 'Sidebar.Faq',
            //     route: 'faq',
            //     //icon: 'help-circle',
            // },
        ],
    },
];
