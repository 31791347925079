<template>
    <b-row
        v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
        class="content-header"
        align-v="center"
    >
        <!-- Content Left -->
        <b-col
            class="content-header-left mb-2"
            :cols="$route.name == 'membersDetail' ? '6' : '12'"
            md="9"
            lg="6"
        >
            <!-- <b-row class="breadcrumbs-top">
                <b-col cols="12">
                    <h2 class="content-header-title float-left pr-1 mb-0">
                        {{ $t($route.meta.pageTitle) }}
                    </h2>
                    <div class="breadcrumb-wrapper">
                        <b-breadcrumb class="align-items-center">
                            <b-breadcrumb-item :to="{name: 'dashboard'}">
                                <mdicon name="home-variant-outline" />
                            </b-breadcrumb-item>
                            <b-breadcrumb-item
                                v-for="item in $route.meta.breadcrumb"
                                :key="item.text"
                                :active="item.active"
                                :to="item.to"
                            >
                                {{ $t(item.text) }}
                            </b-breadcrumb-item>
                        </b-breadcrumb>
                    </div>
                </b-col>
            </b-row> -->
        </b-col>

        <b-col
            v-if="$route.name == 'membersDetail'"
            class="content-header-left mb-2 d-flex justify-content-end"
            cols="6"
            md="9"
            lg="6"
        >
            <b-link
                :to="{name: 'golfRelatedMembers'}"
                class="d-flex text-secondaryColor align-items-center"
            >
                <mdicon :width="30" :height="30" name="chevron-left" />
                <h6
                    class="mb-0 text-secondaryColor"
                    v-text="$t(locale.backOverview)"
                />
            </b-link>
        </b-col>
    </b-row>
</template>

<script>
import {BBreadcrumb, BBreadcrumbItem, BRow, BCol, BButton} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';

export default {
    directives: {
        Ripple,
    },
    components: {
        BBreadcrumb,
        BBreadcrumbItem,
        BRow,
        BCol,
        BButton,
    },
    data() {
        return {
            locale: {
                backOverview: 'golfRelatedCard.detail.backOverview',
            },
        };
    },
};
</script>
