<template>
    <CustomNoHeaderModalVue v-if="openBuySupport" width="w-11/12 md:w-1/2" :title="''" @handleClose="closeModal" :close="!true">
      <div class="flex flex-col p-1" slot="header">
        <h2 class="text-xl md:text-2xl font-semibold font-poppins text-gray-900">{{ $t(locale.hoursTitle) }}</h2>
      </div>
      <div class="flex flex-col p-2" slot="body">
        <div class="mb-4" style="white-space: pre-line;">
          {{ $t(locale.informationText) }}
        </div>
  
        <ValidationObserver v-slot="{ invalid }">
          <!-- Input for description -->
          <ValidationProvider :rules="descriptionRules" v-slot="{ errors }">
            <b-form-group :label="$t(locale.supportDescriptionLabel)">
                <b-form-textarea
                v-model="supportDescription"
                :placeholder="$t(locale.supportDescriptionPlaceholder)"
                rows="4"
                max-rows="6"
                ></b-form-textarea>
                <span class="text-danger">{{ errors[0] }}</span>
            </b-form-group>
            </ValidationProvider>

            <!-- Input for hours -->
            <ValidationProvider :rules="hoursRules" v-slot="{ errors }">
            <b-form-group :label="$t(locale.supportHoursLabel)">
                <b-form-input
                type="number"
                step="0.1"
                v-model.number="supportHours"
                :placeholder="$t(locale.supportHoursPlaceholder)"
                />
                <span class="text-danger">{{ errors[0] }}</span>
            </b-form-group>
          </ValidationProvider>
        
          <div class="mt-2">
            <span>{{ $t(locale.totalCostLabel) }}: {{ totalCost }} SEK exkl. moms</span>
          </div>
          <div class="flex justify-end mt-4">
            <button @click="closeModal" class="btn btn-secondary mr-2">{{ $t(locale.cancel) }}</button>
            <button :disabled="invalid" @click="buySupportHours" class="btn btn-primary">{{ $t(locale.proceed) }}</button>
          </div>
        </ValidationObserver>
      </div>
    </CustomNoHeaderModalVue>
  </template>
  
  
  <script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import useAppConfig from '@core/app-config/useAppConfig';
import CustomNoHeaderModalVue from '@/@core/customComponent/CustomNoHeaderModal.vue';
import bus from './../bus';
import { mapGetters } from "vuex";
import { extend } from 'vee-validate';
import { min_value } from 'vee-validate/dist/rules';

extend('min_value', {
  ...min_value,
  message: 'Antal timmar måste vara mer än 0.',
});
extend('required', {
    message: 'Ett värde måste anges.',
});


export default {
  props: {
    openBuySupport: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    CustomNoHeaderModalVue
  },
  data() {
    return {
      loadingStatus: false,
      supportDescription: '',
      supportHours: 0,
      locale: {
        hoursTitle: 'SupportCard.hoursTitle',
        informationText: 'SupportCard.informationText',
        supportDescriptionLabel: 'SupportCard.supportDescriptionLabel',
        supportDescriptionPlaceholder: 'SupportCard.supportDescriptionPlaceholder',
        supportHoursLabel: 'SupportCard.supportHoursLabel',
        totalCostLabel: 'SupportCard.totalCostLabel',
        cancel: 'SupportCard.cancel', 
        proceed: 'SupportCard.order'
      },
    };
  },
  computed: {
    totalCost() {
      return this.supportHours * 1500;
    },
    message() {
      return this.GET_LOCALE === 'sv' ? 'message' : 'eng_message';
    },
    ...mapGetters({
      GET_LOCALE: 'appConfig/getLocale'
    }), 
    descriptionRules() {
        return {
        required: { message: this.$t('Validation.required') },
        };
    },
    hoursRules() {
        return {
            required: { message: this.$t('Validation.required') },
            min_value: { min: 0.1, message: this.$t('Validation.MinHours', { min: 0.1 }) },
        };
        },




  },
  methods: {
    closeModal() {
      this.openBuySupport = false;
      this.supportDescription = '';
      this.supportHours = 0;
    },
    buySupportHours() {
      this.loadingStatus = true;

      const dataForm = {
        description: this.supportDescription,
        hours: this.supportHours,
      };
      this.$useJwt.buySupportHours(dataForm)
        .then(response => {
          this.loadingStatus = false;
          this.openBuySupport = false;

          // Display success message (replace `responseMessagePath` with the actual path to your message in the response)
          this.popupMsg(
            this.$t('Message.Success'),
            response.data[this.message],
            'CheckIcon',
            'success'
        );
        })
        .catch(error => {
          this.loadingStatus = false;

          // Display error message (replace `errorMessagePath` with the actual path to your message in the error)
          this.popupMsg(
                this.$t('Message.Failed'),
                error.response ? error.response.data.message : "Ett fel uppstod, kontakta supporten på info@golfigo.se för assistans.",
                'AlertTriangleIcon',
                'danger'
            );
        });
    },
  },
  created() {
    bus.$on('open-buy-support', () => {
      this.openBuySupport = true;
    });
  },
};
</script>
