<template>
  <div
      class="app-content content"
      :class="[{'show-overlay': $store.state.app.shallShowOverlay}, $route.meta.contentClass]"
  >
    <div class="content-overlay"/>
    <div class="access-denied-overlay"/>
    <div class="header-navbar-shadow"/>
    <transition
        :name="routerTransition"
        mode="out-in"
    >
      <div
          class="content-area-wrapper"
          :class="contentWidth === 'boxed' ? 'container p-0' : null"
      >
        <slot name="breadcrumb">
          <app-breadcrumb/>
        </slot>
        <portal-target
            name="content-renderer-sidebar-left"
            slim
        />
        <div class="content-right">
          <div class="content-wrapper">
            <div class="content-body">
              <slot/>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue'
import useAppConfig from '@core/app-config/useAppConfig'

export default {
  components: {
    AppBreadcrumb,
  },
  setup() {
    const {routerTransition, contentWidth} = useAppConfig()

    return {
      routerTransition, contentWidth,
    }
  },
}
</script>

<style>
.access-denied-overlay {
  position: fixed;
  opacity: 0.6;
  width: 90%;
  height: 100%;
  top: 0;
  left: 154px;
  right: 0px;
  bottom: 0;
  background-color: rgb(0 0, 0.5) !important;
  cursor: pointer;
  transition: all 0.7s, z-index 0s;
  z-index: 15;
}
</style>
