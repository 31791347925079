<template>
    <ul class="mt-4">
        <component
            :is="resolveNavItemComponent(item)"
            v-for="item in items"
            v-if="item.hidden === false"
            :key="item.header || item.title"
            :item="item"
        />

        <!-- <SupportModal /> -->
    </ul>
</template>

<script>
import {resolveVerticalNavMenuItemComponent as resolveNavItemComponent} from '@core/layouts/utils';
import {provide, ref} from '@vue/composition-api';
import VerticalNavMenuHeader from '../vertical-nav-menu-header';
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue';
import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue';
import {useUtils as useI18nUtils} from '@core/libs/i18n';
import SupportModal from '@/views/SupportModal.vue';

export default {
    components: {
        VerticalNavMenuHeader,
        VerticalNavMenuLink,
        VerticalNavMenuGroup,
        SupportModal,
    },
    props: {
        items: {
            type: Array,
            required: true,
        },
    },
    setup() {
        const {t} = useI18nUtils();
        provide('openGroups', ref([]));

        return {
            t,
            resolveNavItemComponent,
        };
    },
};
</script>
